// Import necessary libraries
import React, { useEffect, useState } from "react";
// import axios from "axios";
import ParticlesBg from "particles-bg";
import { useParams } from "react-router-dom";

import Airtable from "airtable";
import RazorpayButton from "./payBtn";

// Order component
function Order() {
  const base = new Airtable({
    apiKey:
      "patHmSrnvOsCQERId.d00a59cdf43743a642aa2838a733380caabfab47a35d3ada772a786b8724c468",
  }).base("appnexeFLmVSWO5HM");

  const [orderStatus, setOrderStatus] = useState(null);

  // Get order_id from URL params
  const { orderId } = useParams();

  useEffect(() => {
    base("jan").find(orderId, function (err, record) {
      if (err) {
        // console.error(err);
        return;
      }
      setOrderStatus(record._rawJson);
    });
  }, [orderId, base]);

  return (
    <>
      <ParticlesBg num={200} type="thick" bg={true} />

      <div
        style={{
          maxWidth: "250px",
          backgroundColor: "black",
          color: "white",
          padding: "10px",
        }}
      >
        {/* <hr /> */}
        <img src={require("./s2-logo.gif")} width={"100%"} alt="sem2" />
        <h1> Order Status </h1>
        <br />
        <br />

        {orderStatus ? (
          <div>
            {/* <p>
              {" "}
              <b> Order ID</b> : {orderStatus.id}
            </p>{" "} */}
            <p>
              {" "}
              <b> Order ID</b> : {orderStatus.fields.Id}
            </p>
            <p>
              {" "}
              <b> Status</b> : {orderStatus.fields.Status}
            </p>
            <p>
              {" "}
              <b> Amount Balance </b> : {orderStatus.fields.Balance}
            </p>

            {orderStatus.fields.Balance === 40 ? <RazorpayButton /> : <></>}

            <div>
              <b> Subjects</b> :{" "}
              <ol>
                {orderStatus.fields.Items.map((item) => (
                  <li key={item}>{item}</li>
                ))}
              </ol>
            </div>
            {/* Additional order status information can be displayed here */}
          </div>
        ) : (
          <p>Loading... </p>
        )}
      </div>
    </>
  );
}

export default Order;
