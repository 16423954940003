// Import necessary libraries
import React, { useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";

import Airtable from "airtable";
import Swal from "sweetalert2";
// import withReactContent from 'sweetalert2-react-content'
// import ParticlesBg from "particles-bg";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
// import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
// const label = { inputProps: { "aria-label": "Checkbox demo" } };

// Sample data for items
const itemsData = [
  {
    new: false,
    code: "1T1",
    short: "FM",
    name: "FINANCIAL MANAGEMENT",
    price: 50,
  },
  {
    new: false,
    code: "1T2",
    short: "MM",
    name: "MARKETING MANAGEMENT",
    price: 50,
  },
   { new:false, code: "1T3", short: "HRM", name: "HUMAN RESOURCE MANAGEMENT", price: 55 },
  {
    new: false,
    code: "1T4",
    short: "OM",
    name: "OPERATIONS MANAGEMENT",
    price: 65,
  },
  {
    new: false,
    code: "1T5",
    short: "IB",
    name: "INTERNATIONAL BUSINESS",
    price: 85,
  },
  {
    code: "1T6",
    short: "CSR",
    name: "CORPORATE SOCIAL RESPONSIBILITY AND SUSTAINABILITY",
    price: 40,
  },
  { new: false, code: "1T7", short: "CA", name: "COST ACCOUNTING", price: 45 },
  // { new:false, code: "1T8", short: "MCA", name: "MANAGEMENT CASE ANALYSIS", price: 10 },
  {
    new: true,
    code: "FRONT PAGES",
    short: "FRONT PAGES",
    name: "FRONT PAGES",
    price: 50,
  },
];

const fullNames = [
  { label: "AACHAL YUVRAJ VAIDYA" },
  { label: "ABHISHEK MADAN BHUTAD" },
  { label: "ABHISHEK SHATRUGHNA AMBHORE" },
  { label: "ABHISHEK SINGH JAIPRAKASH THAKUR" },
  { label: "AISHWARYA SURESH KAMBLE" },
  { label: "AJINKYA SURESH DABERAO" },
  { label: "AKASH DEEPAK NARNAWARE" },
  { label: "AMISHA RAJU YADAV" },
  { label: "AMIT LATESH NINAWE" },
  { label: "AMOL KISANRAO SHETE" },
  { label: "AMREEN IQUBALBEG MIRZA" },
  { label: "ANAND SANJAYRAO DAFARE" },
  { label: "ANIKET PREMANAND GANVIR" },
  { label: "ANKIT SURESH TURKAR" },
  { label: "ANKITA ANKOSH GURPUDE" },
  { label: "ANKITA SANJAY NANDANWAR" },
  { label: "ANSHU SURYABHAN GAJBHIYE" },
  { label: "ANSHUMAN DILIP RAJORIYA" },
  { label: "ANURAG JAYPRAKASH GANJARE" },
  { label: "AYUSH ASHOK SHIRPURKAR" },
  { label: "AYUSH VIJAY FATING" },
  { label: "BHUSHAN KESHVRAO MURKUTE" },
  { label: "DEEPIKA BABLU BHONDEKAR" },
  { label: "DHANASHRI SUNIL NIMKAR" },
  { label: "DIPALI MURARI RAMTEKE" },
  { label: "DIVYA RAJU GAWANDE" },
  { label: "DIVYA TRILOKCHAND BHADKE" },
  { label: "GRISHMI RAJKUMAR KARANDE" },
  { label: "GUNESHWAR MADANLAL BILLORE" },
  { label: "HARSHA VIJAY PARATE" },
  { label: "HARSHAL MADHUKAR KATHADE" },
  { label: "HARSHAL RAMKRUSHNA MAHURE" },
  { label: "HARSHITA CHINTAMAN MOHADIKAR" },
  { label: "HIMANSHU SHYAM CHAWKE" },
  { label: "JANHAVI NILKANTH TIKAS" },
  { label: "JAYABHARTI ASHOK MAHURKAR" },
  { label: "KARAN NANDKISHOR TUMSARKAR" },
  { label: "KHUSHALI RAGHOBA ZADE" },
  { label: "KHUSHI RAJESH TANDE" },
  { label: "KIRTI SUNIL PARATE" },
  { label: "KRUTIKA MUKESH GUPTA" },
  { label: "KUNAL MINIRAJ ZADEKAR" },
  { label: "MANISH PARSHURAM INGLE" },
  { label: "MANISHA BHAGAWATRAO ATALKAR" },
  { label: "MAYURI UMARE" },
  { label: "MILINDKUMAR CHUNNILAL PATLE" },
  { label: "NAINA SHANTARAM KHOBRAGADE" },
  { label: "NAUSHAD ASHOK SAKHARE" },
  { label: "NIKITA ASHOK MALVIYA" },
  { label: "NIKITA MAROYI SAYANKAR" },
  { label: "NILAM NASHIK KHOBRAGADE" },
  { label: "PALLAVI ASHOK SAKHARE" },
  { label: "PANCHSHILA UMEDLAL RAUT" },
  { label: "PANKAJ JITENDRA BUTLE" },
  { label: "POOJA JAGDISH BHALEKAR" },
  { label: "POOJA SURESH ASUTKAR" },
  { label: "PRACHI SUDHAKAR THAKRE" },
  { label: "PRACHI VARMESHWAR KAMBLE" },
  { label: "PRAFULLA MANOHAR MAHAKALKAR" },
  { label: "PRAGATI MAHADEV TAMGADGE" },
  { label: "PRAJWAL LAXMAN KATWE" },
  { label: "PRAJWAL RAJPAL BARSAGADE" },
  { label: "PRAJWAL VIJAYRAO MAKODE" },
  { label: "PRANAY SUDHAKAR GHUBDE" },
  { label: "PRATIKSHA NAINPAL MESHRAM" },
  { label: "PRATIKSHA SANTOSH KAILAKHE" },
  { label: "PRITIJ PRADIP CHAVHAN" },
  { label: "PUNAM MAHADEO TAMGADGE" },
  { label: "RAHUL MURLIDHAR TONGE" },
  { label: "RAHUL SHRIKRUSHNA ZADE" },
  { label: "RAJESHWARI SUKHDAS SAWSAKADE" },
  { label: "RAKESH DHANRAJ NANDANWAR" },
  { label: "RITESH KISHOR MORE" },
  { label: "RITIK PURUSHOTTAM KURHADKAR" },
  { label: "ROHINI VINOD WAGHAYE" },
  { label: "RUCHIKA BALAJI DHARGAVE" },
  { label: "RUTUJA PRABHKAR KALBANDE" },
  { label: "SACHIN PRALHAD MARGHADE" },
  { label: "SAGAR CHANDRAKANT PARATE" },
  { label: "SAKSHI VIJAY KAMBLE" },
  { label: "SANJAY MARUTI KAMBLE" },
  { label: "SANKET KISHOR BURBURE" },
  { label: "SAURABH SAHEBRAO DAKHOLE" },
  { label: "SAURABH SUDHAKAR DONGRE" },
  { label: "SEJAL MAROTI DAHAKE" },
  { label: "SHAILESH JAYDEO MESHRAM" },
  { label: "SHITAL CHANDRASHEKHAR GUMGAONKAR" },
  { label: "SHIVAM MILIND KASHETTIWAR" },
  { label: "SHIVRAJ SANJAYRAO MHASKE" },
  { label: "SHREYASH RAHUL BHAGAT" },
  { label: "SHREYASH SHRAVAN YENURKAR" },
  { label: "SHRUTI ISHWAR RAIKWAD" },
  { label: "SHRUTI JITENDRA DUDHMONGRE" },
  { label: "SHRUTI JIWANDAS CHANDANKHEDE" },
  { label: "SHRUTI KISHOR BOKADE" },
  { label: "SHRUTI PRAKASH DAGWAR" },
  { label: "SHUBHAM SURAJ SARAG" },
  { label: "SHYAM YUVRAJ VAIDYA" },
  { label: "SIMRAN SUJIT PATIL" },
  { label: "SNEHA SHALIKRAM DHANKAR" },
  { label: "SUDHIR SHRIKRUSHNA GAYAKWAD" },
  { label: "SULBHA GORELAL DOHARE" },
  { label: "SUNIDHI TARACHAND GONGALE" },
  { label: "SUSHIL DIGAMBAR THAWARE" },
  { label: "TAPISH PARISH SINGH" },
  { label: "TRUPTI VILAS YEOLE" },
  { label: "UNNATI DHANRAJ WADIBHASME" },
  { label: "UPEKSHA ATMARAM LOKHANDE" },
  { label: "VAIBHAO MANOHAR FULMALI" },
  { label: "VAIBHAV GAUTAM BAGESHWAR" },
  { label: "VAIBHAV MAROTI KOMALWAR" },
  { label: "VAIBHAV VIVEK TAKSANDE" },
  { label: "VANDANA KHELURAM SAHU" },
  { label: "VIKRANT JITENDRA BUTLE " },
  { label: "VISHAL RANABHIL GOSWAMI" },
  { label: "VIVEK RAMAJI NANHE" },
];

// Order component
function Order() {
  // const MySwal = withReactContent(Swal)
  const navigate = useNavigate();

  const base = new Airtable({
    apiKey:
      "patHmSrnvOsCQERId.d00a59cdf43743a642aa2838a733380caabfab47a35d3ada772a786b8724c468",
  }).base("appnexeFLmVSWO5HM");

  const initialFormData = {
    name: "",
    mobile: "",
  };

  // State to manage selected items
  const [selectedItems, setSelectedItems] = useState([]);

  // State to manage total price
  const [totalPrice, setTotalPrice] = useState(0);
  // const [rec, setRec] = useState("");
  // const [recView, setRecView] = useState("");

  // State to manage form data
  const [formData, setFormData] = useState({
    name: "",
    mobile: "",
  });

  // Function to handle checkbox changes
  const handleCheckboxChange = (itemShort, itemPrice) => {
    // console.log(itemShort, itemPrice);
    setSelectedItems((prevSelectedItems) => {
      if (prevSelectedItems.includes(itemShort)) {
        // If the item is already selected, remove it
        const newSelectedItems = prevSelectedItems.filter(
          (short) => short !== itemShort
        );
        setTotalPrice((prevTotalPrice) => Math.max(0, totalPrice - itemPrice));
        return newSelectedItems;
      } else {
        // If the item is not selected, add it
        const newSelectedItems = [...prevSelectedItems, itemShort];
        setTotalPrice((prevTotalPrice) => totalPrice + itemPrice);
        // console.log(totalPrice);
        return newSelectedItems;
      }
    });
  };

  // Function to handle input changes
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  // Function to handle order now button click
  const handleOrderNow = async () => {
    // try {
    //   const response = await axios.post("https://api.example.com/order", {
    //     items: selectedItems,
    //     totalPrice: totalPrice, // Add total price to the request
    //   });

    //   // Display a thank you popup
    //   alert("Thank you for your order!");
    // } catch (error) {
    //   // Handle error
    //   console.error("Error placing order:", error);
    // }

    // add record

    setPlacingOrder(true);

    base("jan").create(
      [
        {
          fields: {
            Items: selectedItems,
            Amt: totalPrice,
            Name: formData.name,
          },
        },
      ],
      function (err, records) {
        if (err) {
          console.error(err);
          return;
        }
        records.forEach(function (record) {
          // console.log(record._rawJson);
          // setRec(record._rawJson);
          // setRecView(window.location.href + record._rawJson.id);
          // console.log(recView);

          const options = {
            method: "POST",
            url: "https://api.telegram.org/bot6416067470%3AAAF0EzEVR3rPklGU4vXf5ALV6owZwIssRkA/sendMessage",
            headers: {
              accept: "application/json",
              "content-type": "application/json",
            },
            data: {
              text:
                JSON.stringify(record._rawJson) +
                "\n\n" +
                "Name : " +
                formData.name +
                "\n\n" +
                "Mobile : " +
                formData.mobile +
                "\n\n" +
                window.location.href +
                record._rawJson.id,
              disable_web_page_preview: false,
              disable_notification: false,
              reply_to_message_id: null,
              chat_id: "-1002061047080",
            },
          };

          axios
            .request(options)
            .then(function (response) {
              console.log(response.data);
            })
            .catch(function (error) {
              console.error(error);
            })
            .then((res) => {
              setFormData(initialFormData);
              setSelectedItems([]);
              setTotalPrice(0);
              // setRec("");
              // setRecView("");
              // window.location.reload();

              // alert("Order placed");

              setPlacingOrder(false);

              if (totalPrice === 40) {
                navigate("/" + record._rawJson.id);
              }

              if (totalPrice !== 40) {
                Swal.fire({
                  title: `Thanks ${formData.name}, Your Order is placed successfully`,
                  html: `Order Amount : <b> ${totalPrice}/-</b><br/>Subjects : <b>${selectedItems}</b><br/><br/>Shortly you will receive order tracking on WhatsApp`,
                  icon: "success",
                  confirmButtonText: "OK",
                });
              }
              // record._rawJson.id
            });
        });
      }
    );
  };
  const [placingOrder, setPlacingOrder] = useState(false);

  return (
    <>
      <br />
      <br />
      <Stack direction="row" spacing={2}>
        <Paper square={false} elevation={24}>
          <div
            style={{
              // maxWidth: "300px",
              width: "300px",
              backgroundColor: "white",
              color: "black",
              padding: "10px",
              borderRadius: "50px",
            }}
          >
            {formData.name && formData.mobile.length === 10 ? (
              <>
                <img src={require("./s2-logo.gif")} width={"100%"} alt="sem2" />
              </>
            ) : (
              <></>
            )}
            <Typography variant="h5" gutterBottom>
              Place an Order
            </Typography>
            <hr />
            <Autocomplete
              freeSolo
              options={fullNames}
              id="name"
              name="name"
              // value={formData.name}
              // onChange={handleInputChange}

              onInputChange={(event, newInputValue) => {
                setFormData({ ...formData, name: newInputValue });
              }}
              required
              style={{ width: "100%" }}
              renderInput={(params) => <TextField {...params} label="Name" />}
              filterOptions={(options, state) => {
                if (state.inputValue.length < 4) {
                  return [];
                }

                return options.filter((option) =>
                  option.label
                    .toLowerCase()
                    .includes(state.inputValue.toLowerCase())
                );
              }}
            />

            <br />
            <TextField
              label="Mobile Number"
              variant="outlined"
              id="mobile"
              name="mobile"
              value={formData.mobile}
              onChange={handleInputChange}
              required
              style={{ width: "100%" }}
              pattern="[0-9]{10}"
              type="number"
            />

            {/* <hr /> */}
            {/* <img src={require("./wo_cover.jpg")} width={"100%"} alt="sem2" /> */}
            {/* <img src={require("./with_cover.jpg")} width={"100%"} alt="sem2" /> */}

            {formData.name && formData.mobile.length === 10 ? (
              <>
                <div>
                  <hr />

                  <FormGroup>
                    {itemsData.map((item) => (
                      <FormControlLabel
                        key={item.short}
                        control={
                          <Checkbox
                            sx={{ "& .MuiSvgIcon-root": { fontSize: 28 } }}
                            id={`item-${item.short}`}
                            checked={selectedItems.includes(item.short)}
                            onChange={() =>
                              handleCheckboxChange(item.short, item.price)
                            }
                          />
                        }
                        label={item.short + " - ₹" + item.price}
                        htmlFor={`item-${item.short}`}
                      />
                    ))}
                  </FormGroup>
                </div>
                <br />
                {/* Name input */}
                {/* <TextField
              label="Your Name"
              variant="outlined"
              id="name"
              name="name"
              value={formData.name}
              onChange={handleInputChange}
              required
              style={{ width: "100%" }}
            /> */}

                <Typography variant="h5" gutterBottom>
                  Total Price : <strong> ₹{totalPrice} </strong>
                </Typography>
                <br />
                {!placingOrder ? (
                  <Button
                    onClick={handleOrderNow}
                    variant="contained"
                    color="secondary"
                    disabled={
                      totalPrice === 0 ||
                      formData.name === "" ||
                      formData.mobile === ""
                    }
                  >
                    <b> Place Order</b>
                  </Button>
                ) : (
                  <>
                    <img
                      src={require("./loading.gif")}
                      width={"100%"}
                      alt="sem2"
                    />
                    <p> Placing Order... </p>
                  </>
                )}
                <br />
                <br />
                <br />
                <br />
                <br />
              </>
            ) : (
              <></>
            )}
          </div>
        </Paper>
      </Stack>
      <br />
      <br />
    </>
  );
}

export default Order;
