// Import necessary dependencies
import React, { useEffect, useState } from "react";
import "./Cricket.css";

import Airtable from "airtable";

// Functional component for the scoreboard
const Scoreboard = () => {
  const base = new Airtable({
    apiKey:
      "patYUTSq2PerF8SEk.94b57e1eace5bb86c43e0eebc2ee2e1383d6995e9ee8473eb18270a0e7f0dffe",
  }).base("appPyowGcb4IJ3rcQ");

  const [teamA, setTeamA] = useState(null);
  const [teamB, setTeamB] = useState(null);

  useEffect(() => {
    // base("20Jan")
    //   .select({
    //     maxRecords: 2,
    //     view: "Grid view",
    //   })
    //   .eachPage(
    //     function page(records, fetchNextPage) {
    //       records.forEach(function (record) {
    //         console.log("Retrieved", record.get("Name"));
    //         console.log("Retrieved ID", record.id);
    //         setTeamAName(record.get("Name"))
    //       });

    //       fetchNextPage();
    //     },
    //     function done(err) {
    //       if (err) {
    //         console.error(err);
    //         return;
    //       }
    //     }
    //   );

    base("20Jan").find("recuQLt5YXiQWbypL", function (err, record) {
      if (err) {
        console.error(err);
        return;
      }
      console.log("Retrieved TeamA", record._rawJson);
      setTeamA(record._rawJson);
    });

    base("20Jan").find("recjMjzmCcPb9JH6E", function (err, record) {
      if (err) {
        console.error(err);
        return;
      }
      console.log("Retrieved TeamB", record._rawJson);
      setTeamB(record._rawJson);
    });
  });

  // State to manage scores and other match details
  // const [teamAScore, setTeamAScore] = useState(0);

  // const [teamAName, setTeamAName] = useState("");
  // const [teamAWickets, setTeamAWickets] = useState(0);
  // const [teamAOvers, setTeamAOvers] = useState(0);

  // const [teamBScore, setTeamBScore] = useState(0);
  // const [teamBName, setTeamBName] = useState(0);
  // const [teamBWickets, setTeamBWickets] = useState(0);
  // const [teamBOvers, setTeamBOvers] = useState(0);

  // const [openingBatsmanA, setOpeningBatsmanA] = useState("Player A");
  // const [currentBatsmanA, setCurrentBatsmanA] = useState("Player B");
  // const [openingBatsmanB, setOpeningBatsmanB] = useState("Player C");
  // const [currentBatsmanB, setCurrentBatsmanB] = useState("Player D");

  // const [currentBowlerA, setCurrentBowlerA] = useState("Player E");
  // const [currentBowlerB, setCurrentBowlerB] = useState("Player F");

  // const [tossWinner, setTossWinner] = useState(null);
  // const [tossDecision, setTossDecision] = useState(null);

  // const [matchWinner, setMatchWinner] = useState(null);

  // // Function to simulate the toss and decision
  // // const simulateToss = (team) => {
  // //   const tossDecision = Math.random() < 0.5 ? 'bat' : 'bowl';

  // //   setTossWinner(team);
  // //   setTossDecision(tossDecision);
  // // };

  // // Function to update scores and details
  // const updateScore = (team) => {
  //   if (team === "A") {
  //     setTeamAScore(teamAScore + 1);
  //     // Update overs (assuming each click is a ball)
  //     setTeamAOvers(Math.floor(teamAScore / 6) + (teamAScore % 6) / 10);
  //   } else {
  //     setTeamBScore(teamBScore + 1);
  //     // Update overs (assuming each click is a ball)
  //     setTeamBOvers(Math.floor(teamBScore / 6) + (teamBScore % 6) / 10);
  //   }
  // };

  // // Function to determine the match winner
  // const determineMatchWinner = () => {
  //   if (teamAScore > teamBScore) {
  //     setMatchWinner("Team A");
  //   } else if (teamBScore > teamAScore) {
  //     setMatchWinner("Team B");
  //   } else {
  //     setMatchWinner("It's a Tie!");
  //   }
  // };

  // // Determine the match winner when the component mounts
  // if (!matchWinner && teamAOvers > 8 && teamBOvers > 1) {
  //   determineMatchWinner();
  // }

  return (
    <div className="scoreboard">
      <h2> Suryodaya College of Engineering & Technology, Nagpur </h2>
      <h2> Cricket Scoreboard</h2>
      {/* {tossWinner && tossDecision && (
        <p>
          Toss won by {tossWinner}, elected to {tossDecision}
        </p>
      )} */}
      <table>
        <thead>
          <tr>
            <th>Team</th>
            <th>Runs</th>
            <th>Wickets</th>
            <th>Overs</th>
            <th>Opening Batsman</th>
            <th>Current Bowler</th>
          </tr>
        </thead>
        <tbody>
          {teamA ? (
            <tr>
              <td> {teamA.fields.Name} </td>
              <td>{teamA.fields.Score}</td>
              <td>{teamA.fields.Wickets}</td>
              <td>{teamA.fields.Overs}</td>
              <td>{teamA.fields.Batsman}</td>
              <td>{teamA.fields.Bowler}</td>
            </tr>
          ) : (
            <p>Loading... </p>
          )}
          {teamB ? (
            <tr>
              <td> {teamB.fields.Name} </td>
              <td>{teamB.fields.Score}</td>
              <td>{teamB.fields.Wickets}</td>
              <td>{teamB.fields.Overs}</td>
              <td>{teamB.fields.Batsman}</td>
              <td>{teamB.fields.Bowler}</td>
            </tr>
          ) : (
            <p>Loading... </p>
          )}
        </tbody>
      </table>
      {/* {matchWinner && (
        <p className="match-winner">{`Match Winner: ${matchWinner}`}</p>
      )} */}
      <div className="buttons">
        {/* <button onClick={() => simulateToss('A')}>Simulate Toss for Team A</button> */}
        {/* <button onClick={() => simulateToss('B')}>Simulate Toss for Team B</button> */}
        {/* <button onClick={() => updateScore('A')}>Add Run to Team A</button> */}
        {/* <button onClick={() => updateScore('B')}>Add Run to Team B</button> */}
      </div>
    </div>
  );
};

// App component
const App = () => {
  return (
    <div className="app">
      <Scoreboard />
    </div>
  );
};

export default App;
