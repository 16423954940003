import React, { useEffect, useRef } from "react";

function RazorpayButton() {
  const formRef = useRef();

  useEffect(() => {
    const form = formRef.current;
    const script = document.createElement("script");

    script.src = "https://checkout.razorpay.com/v1/payment-button.js";
    script.async = true;
    script.setAttribute("data-payment_button_id", "pl_NgGXXRLZ9Hr9ZC");

    setTimeout(() => {
      form.appendChild(script);
    }, 0);

    return () => {
      form.removeChild(script);
    };
  }, []);

  return <form id="payment-form" ref={formRef}></form>;
}

export default RazorpayButton;
