import "./App.css";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import OrderStatus from "./OrderStatus";
// import Order from "./Order";
import OrderFuture from "./OrderFuture";
import Cricket from "./Cricket";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        {/* <Route path="/" element={<Order />} /> */}
        <Route path="/" element={<OrderFuture />} />
        <Route path="/cricket" element={<Cricket />} />
        <Route path="/:orderId" element={<OrderStatus />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
